div.fullscreen-group {
  z-index: 1;
  position: relative;
}

div.fullscreen-group::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
  pointer-events: none;
  touch-action: none;
  opacity: 0;
  transition: 250ms ease 0s;
}

div.fullscreen-group.fullscreen-group--expanded {
  z-index: 1101;
}

div.fullscreen-group.fullscreen-group--expanded::before {
  opacity: 1;
  z-index: 1101;
  pointer-events: initial;
  touch-action: initial;
}

div.fullscreen-group.fullscreen-group--expanded button.fullscreen-toggle {
  opacity: 1;
}

div.fullscreen-group.fullscreen-group--expanded button.fullscreen-btn {
  cursor: zoom-out;
}

div.fullscreen-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

button.fullscreen-toggle {
  position: fixed;
  top: 0;
  bottom: 0;
  padding: 20px;
  border: none;
  background: none;
  opacity: 0;
  transition: inherit;
  cursor: pointer;
  z-index: 1102;
  outline: none;
}

button.fullscreen-toggle:active {
  background: rgba(0, 0, 0, 0.1);
}

button.fullscreen-toggle.toggle--left {
  left: 0;
}

button.fullscreen-toggle.toggle--right {
  right: 0;
}

button.fullscreen-btn {
  border: none;
  background: none;
  position: inherit;
  top: inherit;
  left: inherit;
  right: inherit;
  bottom: inherit;
  height: 100%;
  width: 100%;
  padding: 0;
  cursor: zoom-in;
  outline: none;
  border: 1px solid #dedede;
  border-radius: 6px;
}

button.fullscreen-exit-btn {
  border: none;
  background: none;
  position: fixed;
  top: 20px;
  right: 0;
  padding: 20px;
  cursor: pointer;
  z-index: 1102;
}

div.fullscreen-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

div.fullscreen-image img {
  position: auto-fit;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

div.fullscreen-image[scaled='1'] img {
  max-width: 75vw;
  max-height: calc(100vh - 136px);
  border-radius: 6px;
}

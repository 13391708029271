/** Color
* Green: #00CE0A
* Blue: #0F40D4
* Red: #FF3419
*/

@import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
  --height-header: 114px;
  --z-index-1: 100;
  --z-index-2: 200;
  --z-index-3: 300;
  --z-index-4: 400;
  --z-index-infinity: 1100;
  --font-primary: 'Hanken Grotesk', sans-serif;
  --font-secondary: 'Hanken Grotesk', sans-serif;
  --bg-orange: radial-gradient(ellipse at 30% 30%, #b52538, #e88c3f);
  --bg-gray: #fafafa;
  /* text */
  --color-text: #0f0f0f;
  --color-text-light: #fefefe;
  --color-text-light2: #bbbbbb;
  --color-title: #0f0f0f;
  --color-text-gray: #606060;
  --color-text-gray2: #9da1a5;
  /* body */
  --color-body: #141414;
  --color-body-2: #1b1b1b;
  /* color */
  --color-light: #fafafa;
  --color-black: #0b0b0b;
  --color-black2: #1b1b1b;
  --color-orange: #e88c3f;
  --color-gray: #414141;
  --color-gray2: #606060;
  --color-gray-light: #e7e2df;
  --color-blue: #1976d2;
}

@media only screen and (max-width: 1024px) {
  :root {
    --height-header: 102px;
  }
}

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
}

body {
  font-family: var(--font-primary);
  font-weight: 400;
  font-size: 16px;
  color: #0f0f0f;
}

body.bg-gray {
  background: var(--bg-gray);
  padding-right: 0 !important;
}

body.no-scroll {
  overflow: hidden;
  padding-right: 8px;
}

ul,
ol {
  list-style: none;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  transition: all 0.3s ease;
}
:hover::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: rgba(135, 135, 135, 0.15);
  transition: all 0.3s ease;
}
:hover::-webkit-scrollbar-thumb {
  background: #878787;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #606060;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.app-icon {
  display: block;
  font-size: 65px;
  width: 1em;
  height: 1em;
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #000;
  transition: 0.3s;
}
.ai-white {
  background-color: #fff;
}

/* @media only screen and (min-width: 768.5px) {
  #root[aria-hidden='true'] > header .section-header {
    width: calc(100% - 8px);
  }
} */

@media only screen and (max-width: 600px) {
  html {
    height: 100vh;
  }
  html,
  body {
    padding: 0 !important;
    overflow-x: hidden;
  }
}

/* Header */
.header-nav-area {
  display: flex;
  align-items: center;
}
.header-nav-area:hover a {
  color: var(--color-gray-light);
  transition: all 0.4s;
}
.header-nav-item {
  position: relative;
  margin: 0 20px;
  display: flex;
  align-items: center;
}
.header-nav-item a {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #fff;
  text-decoration: unset;
}
.header-nav-item:hover .nav-item-link {
  color: #fff;
}
.header-nav-item .app-icon {
  transition: all 0.35s ease;
}
.header-nav-item:hover .app-icon {
  transform: rotate(-10deg);
}
.header-nav-item.icon-left .rolling-text {
  padding-left: 30px;
  z-index: 0;
}
.header-nav-item.icon-left .app-icon {
  margin-right: -24px;
}
.header-nav-item::after {
  content: '';
  position: absolute;
  display: block;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  border-radius: 1px;
  background: var(--bg-orange);
  transition: 0.35s;
}
.header-nav-item:hover::after {
  width: 100%;
}
.header-nav-item.active::after {
  width: 22px;
}
.header-nav-item.underline-1::before {
  position: absolute;
  content: '';
  top: calc(100% - 8px);
  left: -6px;
  width: calc(100% - 12px);
  height: 35px;
  background: var(--bg-orange);
  mask-size: contain;
  mask-repeat: no-repeat;
  transition: 0.3s;
  /* mask-image: url('./images/unit/underline-01.png'); */
  transform: scale(1);
}
.header-nav-item.underline-1:hover::before {
  transform: scale(0.9);
}
.header-nav-item.underline-1::after {
  content: none;
}
.header-nav-item .sub-menu {
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  width: 200px;
  visibility: hidden;
  opacity: 0;
  background-color: #323234;
  -webkit-transform: translateY(10px) perspective(1px);
  transform: translateY(10px) perspective(1px);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: visibility 250ms ease, opacity 250ms ease, transform 0.55s cubic-bezier(0.19, 1, 0.22, 1),
    -webkit-transform 0.55s cubic-bezier(0.19, 1, 0.22, 1);
  transition: visibility 250ms ease, opacity 250ms ease, transform 0.55s cubic-bezier(0.19, 1, 0.22, 1),
    -webkit-transform 0.55s cubic-bezier(0.19, 1, 0.22, 1);
  border-radius: var(--radius-lg);
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05), 0 2px 2px rgba(0, 0, 0, 0.2), 0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05), 0 2px 2px rgba(0, 0, 0, 0.2), 0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.05);
  padding: 10px 0;
  border-radius: 10px;
  min-width: max-content;
}
.header-nav-item .sub-menu::after {
  content: '';
  position: absolute;
  background-color: #323234;
  border-radius: 0;
  width: 16px;
  height: 16px;
  top: -8px;
  left: calc(50% - 42px);
  z-index: -1;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.header-nav-item:hover .sub-menu {
  visibility: visible;
  -webkit-transform: translateZ(0) perspective(1px);
  transform: translateZ(0) perspective(1px);
  opacity: 1;
  -webkit-transition: visibility 150ms cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.35s cubic-bezier(0.19, 1, 0.22, 1),
    transform 0.55s cubic-bezier(0.19, 1, 0.22, 1), -webkit-transform 0.55s cubic-bezier(0.19, 1, 0.22, 1);
  transition: visibility 150ms cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.35s cubic-bezier(0.19, 1, 0.22, 1),
    transform 0.55s cubic-bezier(0.19, 1, 0.22, 1), -webkit-transform 0.55s cubic-bezier(0.19, 1, 0.22, 1);
}
.header-nav-item .sub-menu .menu-item {
  position: relative;
}
.header-nav-item .sub-menu .menu-item .memu-item-link {
  padding: 14px 30px 14px 18px;
  transition: all 0.4s;
}
.header-nav-item .sub-menu .menu-item:hover .memu-item-link {
  padding: 14px 18px 14px 30px;
}
.header-nav-item .sub-menu .menu-item:hover .memu-item-link {
  color: #fff;
}
.header-nav-item .sub-menu .menu-item .memu-item-link::before {
  content: '';
  position: absolute;
  left: 14px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  transition: all 0.4s;
  background: var(--bg-orange);
  height: 2px;
}
.header-nav-item .sub-menu .menu-item:hover .memu-item-link::before {
  width: 10px;
  margin-right: 6px;
}
.header-nav-item:hover .rolling-text .letter,
.header-nav-item:hover .rolling-text.play .letter {
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
}

@media (max-width: 899.95px) {
  .header-nav-item {
    margin: 0 12px;
  }
}

@font-face {
  font-family: 'icon-dino';
  src:  url('../fonts/icon-dino.eot?l7xbwo');
  src:  url('../fonts/icon-dino.eot?l7xbwo#iefix') format('embedded-opentype'),
    url('../fonts/icon-dino.ttf?l7xbwo') format('truetype'),
    url('../fonts/icon-dino.woff?l7xbwo') format('woff'),
    url('../fonts/icon-dino.svg?l7xbwo#icon-dino') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon-dino {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icon-dino' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-facebook:before {
  content: "\e900";
}
.icon-sound-waves .path1:before {
  content: "\e903";
  color: rgb(0, 0, 0);
  opacity: 0.931;
}
.icon-sound-waves .path2:before {
  content: "\e904";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.898;
}
.icon-sound-waves .path3:before {
  content: "\e905";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.894;
}
.icon-sound-waves .path4:before {
  content: "\e906";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.891;
}
.icon-sound-waves .path5:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.887;
}
.icon-sound-waves .path6:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.879;
}
.icon-sound-waves .path7:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.899;
}
.icon-video .path1:before {
  content: "\e90a";
  color: rgb(0, 0, 0);
  opacity: 0.965;
}
.icon-video .path2:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.951;
}
.icon-video .path3:before {
  content: "\e90c";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.951;
}
.icon-video .path4:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.966;
}
.icon-video-1 .path1:before {
  content: "\e90e";
  color: rgb(0, 0, 0);
  opacity: 0.96;
}
.icon-video-1 .path2:before {
  content: "\e90f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.984;
}
.icon-video-1 .path3:before {
  content: "\e910";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.939;
}
.icon-file-pdf:before {
  content: "\eadf";
}
